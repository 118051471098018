import { useEffect, useState } from "react";
import { CharacterInfo, ItemInfo } from "./api";

function getSecretStoneCount(c: CharacterInfo, elephs: ItemInfo[]): number {
  return elephs.find((x) => x.UniqueId === c.UniqueId)?.StackCount ?? 0;
}
function getCharacterGrade(c: CharacterInfo): {
  character: number;
  weapon?: number;
} {
  if (!c) return { character: 0, weapon: 0 };
  if (c.Weapon?.StarGrade) {
    return {
      character: 5,
      weapon: c.Weapon.StarGrade,
    };
  }
  return { character: c.StarGrade };
}

function getSecretStoneRequiredForNextLevel(c: CharacterInfo): number {
  const grade = getCharacterGrade(c);
  if (grade.weapon) {
    const d: Record<number, number> = {
      2: 180,
      3: 0,
    };
    return d[grade.weapon] ?? 0;
  }
  const d: Record<number, number> = {
    1: 30,
    2: 80,
    3: 100,
    4: 120,
    5: 120,
  };
  return d[grade.character] ?? 0;
}

function getCharacterGradeString(c: CharacterInfo): string {
  const grade = getCharacterGrade(c);
  if (grade.character === 5) {
    return `固有${grade.weapon ?? 1}`;
  }
  return `★${grade.character}`;
}

function getSecretStoneDiff(c: CharacterInfo, elephs: ItemInfo[]): number {
  return Math.max(
    0,
    getSecretStoneRequiredForNextLevel(c) - getSecretStoneCount(c, elephs)
  );
}

function getCharacterIconUrl(id: number): string {
  return `https://schaledb.com/images/student/icon/${id}.webp`;
}

interface CharacterData extends CharacterInfo {
  elephsDiff: number;
  iconUrl: string;
  grade: string;
}

export function CharacterTable(props: {
  characters: CharacterInfo[];
  elephs: ItemInfo[];
}): JSX.Element {
  const columns = ["Icon", "Name", "絆", "Grade", "Next"];

  const [data, updateData] = useState<CharacterData[]>([]);

  useEffect(() => {
    updateData(
      props.characters
        .map(
          (c) =>
            ({
              ...c,
              elephsDiff: getSecretStoneDiff(c, props.elephs),
              iconUrl: getCharacterIconUrl(c.UniqueId),
              grade: getCharacterGradeString(c),
            } as CharacterData)
        )
        .sort((a, b) => {
          if (
            a.elephsDiff === b.elephsDiff ||
            (a.elephsDiff <= 0 && b.elephsDiff <= 0)
          ) {
            return b.FavorRank === a.FavorRank
              ? b.FavorExp - a.FavorExp
              : b.FavorRank - a.FavorRank;
          }
          return a.elephsDiff - b.elephsDiff;
        })
    );
  }, [props.characters, props.elephs]);

  return (
    <div>
      {data.length > 0 ? (
        <table className="table table-responsive">
          <thead>
            <tr>
              {columns.map((c) => (
                <th key={c}>{c}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((c) => (
              <tr key={c.DevName}>
                <td>
                  <img width={35} src={c.iconUrl} alt={c.DevName} />
                </td>
                <td>{c.Name}</td>
                <td>{c.FavorRank}</td>
                <td>{c.grade}</td>
                <td>{c.elephsDiff}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        "loading..."
      )}
    </div>
  );
}
